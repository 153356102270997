.slider {
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  background-color: transparent;
  height: 50vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.slide {
  min-width: 45%;
  height: 50vh;
  transition: 0.5s;
  overflow: hidden;
  background-color: #363636;
  margin-right: 1.5em;
}

.ghbLnk {
  max-width: 1.6vw;
  margin-top: 3%;
  margin-left: 5%;
}

.fwrdSlash {
  color: black;
}

.controllerDesc {
  font-family: "Muli", sans-serif;
  font-weight: regular;
  color: black;
  font-size: 1em;
  line-height: 1.5em;
  margin-left: 10em;
}

.goLeft {
  border: none;
  background-color: transparent;
  transition: 0.5s;
  margin-right: 1em;
  outline: none;
}

.goRight {
  border: none;
  background-color: transparent;
  transition: 0.5s;
  margin-left: 1em;
  outline: none;
}

.goLeft:hover {
  cursor: pointer;
  transition: 0.5s;
  color: black;
}

.goRight:hover {
  cursor: pointer;
  transition: 0.5s;
  color: black;
}

button:active {
  outline: none;
  border: none;
}

button {
  font-family: "Muli", sans-serif;
  font-size: 0.9em;
  font-weight: regular;
  background-color: transparent;
  border: none;
  color: #b2b2b2;
  outline: none;
}

.sliderController {
  background-color: white;
  height: 50vh;
  width: 25%;
  align-self: flex-start;
  align-items: center;
  align-content: flex-start;
  display: flex;
}

.sliderContainer {
  height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1650px) {
  .slide {
    margin-right: 1.2em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1440px) {
  .ghbLnk {
    max-width: 1.8vw;
  }
  .sliderController {
    width: 26%;
  }
  .slide {
    margin-right: 1.2em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1345px) {
  .sliderController {
    width: 30%;
  }
  .slide {
    margin-right: 1.1em;
  }
  .ghbLnk {
    max-width: 2.2vw;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1240px) {
  .sliderController {
    width: 32%;
  }
  .ghbLnk {
    max-width: 2vw;
  }
  .slide {
    margin-right: 1.05em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1180px) {
  .ghbLnk {
    max-width: 2.5vw;
    margin-top: 5%;
    margin-left: 5%;
  }
  .sliderController {
    width: 34%;
  }
  .slide {
    margin-right: 0.9em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1140px) {
  .sliderController {
    width: 35%;
  }
  .ghbLnk {
    max-width: 2.3vw;
  }
  .slide {
    margin-right: 0.9em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1115px) {
  .sliderController {
    width: 36%;
  }
  .ghbLnk {
    max-width: 2.7vw;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1115px) {
  .sliderController {
    width: 40%;
  }
  .slide {
    margin-right: 0.8em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1024px) {
  .slide {
    min-width: 86.5%;
    margin-right: 1.4em;
  }

  .ghbLnk {
    max-width: 2.8vw;
    margin-top: 3%;
    margin-left: 7.5%;
  }

  .controllerDesc {
    font-size: 0.8rem;
    line-height: 1.8em;
    margin-left: 3em;
  }

  .goLeft {
    margin-right: 0.8rem;
  }

  .goRight {
    margin-left: 0.8rem;
  }

  button {
    font-size: 0.9rem;
  }
  .fwrdSlash {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 0.9rem;
  }
  .sliderController {
    width: 24%;
  }
  .descText {
    font-size: 0.8rem; 
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 780px) {
  .sliderController {
    width: 28%;
  }
  .slide {
    margin-right: 1.1em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 678px) {
  .sliderController {
    width: 34%;
  }
  .slide {
    margin-right: 0.9em;
  }
  .ghbLnk {
    max-width: 4.5vw;
    margin-top: 5.5%;
    margin-left: 7.5%;
  }
}

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

@media only screen and (max-width: 642px) {
  
  .ghbLnk {
    max-width: 4.4vw;
  }
  .controllerDesc {
    font-size: 0.8rem;
    line-height: 1.7em;
  }

  .fwrdSlash {
    font-size: 0.8rem;
  }
  .sliderController {
    width: 39%;
  }
  .descText {
    font-size: 0.8rem; 
  }

}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 600px) {
  .sliderController {
    width: 40%;
  }
  .slide {
    margin-right: 0.8em;
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for mobile phones */
@media only screen and (max-width: 575px) {
  .ghbLnk {
    max-width: 5.5vw;
  }
  .goLeft {
    margin-right: 0.3em;
  }
  .goRight {
    margin-left: 0em;
  }

  button {
    font-size: 0.8rem;
  }

  .sliderController {
    width: 35%;
  }

  .descText {
    display: none;
  }
  .fwrdSlash {
    margin-left: 0;
    margin-right: 0.5em;
    padding-left: 0;
    padding-right: 0;
  } 
  .slide {
    margin-right: 0.7em;
  }
}


/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

@media only screen and (max-width: 464px) {
  .sliderContainer {
    max-height: 100%;
    overflow: hidden;
    display: flex;
  }
  
  .slide {
    margin-right: 3%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: hidden;
    display: flex;
    position: unset;
    height: 100%;
  }


  .ghbLnk {
    max-width: 7vw;
    margin-left: 1em;
    justify-content: center;
    align-items: center;
    margin-top: 1.5em;
  }

  .controllerDesc {
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: 1.2rem;
    margin-right: 0.5rem;
  }

  .goLeft {
    margin-right: 0.3em;
  }

  .goRight {
    margin-left: 0em;
  }

  button {
    font-size: 1rem;
  }

  .sliderController {
    width: 30%;
  }

  .descText {
    display: none;
  }
  .fwrdSlash {
    margin-left: 0;
    margin-right: 0.5em;
    padding-left: 0;
    padding-right: 0;
  }
}
