main {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: calc(100vh - 2em);
}

.App {
  margin: 0;
  padding: 0;

  height: calc(100vh - 2em);
}
