.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #FFF9F3;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.contactText {
  font-family: 'Muli', sans-serif;
  font-size: 1.8em;
  line-height: 1.7em;
  font-weight: 700;
  color: black;
  margin-left: 10rem;
  margin-right: 10rem;
  padding-top: 2em;  
}

.contactLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  padding-bottom: 2.8em;
  padding-top: 2.8em;
  }


  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10rem;
  }

  .aboutTextContainer {
    background-color: #FF01D6;
    position: absolute;
    top: 57%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }

  .photoContainer {
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   width: 100%;
  }

.aboutText {
  font-family: 'Muli', sans-serif;
  font-size: 1.8em;
  line-height: 1.7em;
  font-weight: 700;
  color: white;
  margin-left: 10rem;
  margin-right: 10rem;
  padding-top: 1em;
  padding-bottom: 1em;
}

.mailIcon {
  max-width: 57px;
  margin-right: 3em;
}

.githubIcon {
  max-width: 54px;
  margin-right: 3em;
}
.linkedinIcon {
  max-width: 50px;
  margin-right: 3em;
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
@media (max-width: 1900px) {
  .aboutText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  } 
  .contactText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1760px) {
  .aboutText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  } 
  .contactText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1440px) {
  .aboutTextContainer {
    top: 50%;
  }
  .aboutText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  } 
  .contactText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  } 
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1345px) {
  .aboutText {
    font-size: 1.4em;
    line-height: 2.1em;
    font-weight: 550;
  } 
  .contactText {
    font-size: 1.4em;
    line-height: 2.1em;
    font-weight: 550;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1140px) {
  .aboutText {
    font-size: 1.4em;
    line-height: 1.8em;
    font-weight: 500;
  } 
  .contactText {
    font-size: 1.4em;
    line-height: 1.8em;
    font-weight: 500;
  } 
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

/* media query for tablet */
@media only screen and (max-width: 1024px) {
  .aboutText { 
    margin-left: 3rem;
    margin-right: 3rem;
    padding-top: 1.1em;
    padding-bottom: 1.1em;
    font-size: 1.3rem;
    line-height: 2rem;
  } 

  .contactText {
    margin-left: 3rem;
    margin-right: 3rem;
    padding-top: 1.1em;
    padding-bottom: 0.5em;
    font-size: 1.4rem; 
  }

  .mailIcon {
    max-width: 52px;
  }
  
  .githubIcon {
    max-width: 50px;
  }
  .linkedinIcon {
    max-width: 46px;
  }

  .contactLinks {
    padding-bottom: 1.3em;
    padding-top: 0.8em;
    }

}

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

/* media query for tablet */
@media only screen and (max-width: 780px) {
  .aboutText { 
    margin-left: 2em;
    margin-right: 2em;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    font-size: 1.2em;
    line-height: 2em;
    font-weight: 550;
  } 

  .contactText { 
    margin-left: 2em;
    margin-right: 2em;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    font-size: 1.2em;
    line-height: 2em;
    font-weight: 550;
  } 
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for mobile phones */
@media only screen and (max-width: 575px) {
  .aboutText { 
    font-size: 1.1em;
    line-height: 2em;
    font-weight: 500;
  } 

  .contactText { 
    font-size: 1.1em;
    line-height: 2em;
    font-weight: 500;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

/* media query for mobile phones */
@media only screen and (max-width: 464px) {
  .aboutText { 
    margin-left: 1.5em;
    margin-right: 1.5em;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    font-size: 1.2em;
    line-height: 2em;
    font-weight: 400;
  } 
  .portrait {
  
  margin-top: -2em;
  width: 100%;
   }
  

   .aboutTextContainer {
    top: 50%; 
  }
  

  .contactContainer {
    top: 50%; 
  }
  
  .contactText {
    margin-left: 1em;
    margin-right: 1.5em;
    padding-top: 1.5em;
    padding-bottom: 1.2em;
    font-size: 1.2em;
    line-height: 2em;
    font-weight: 400;
  }
  
  .contactLinks {
    padding-bottom: 2.8em;
    padding-top: 0.8em;
    }
  
    .mailIcon {
      max-width: 52px;
    }
    
    .githubIcon {
      max-width: 50px;
    }
    .linkedinIcon {
      max-width: 46px;
    }

  

}
