@import url(https://fonts.googleapis.com/css2?family=Anton&family=Lato&family=Muli:wght@400;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 16px;
}

body {
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Anton", sans-serif;
  font-family: "Muli", sans-serif;
  height: 98vh;
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 18px;
  }
}

/* media query for mobile phones */
/* show only one slide */
@media only screen and (max-width: 464px) {
  html {
    font-size: 14px;
  }
}

main {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: calc(100vh - 2em);
}

.App {
  margin: 0;
  padding: 0;

  height: calc(100vh - 2em);
}

.heroDiv {
  width: 100%;
  padding-top: 21.5%;
  background-image: url(/static/media/pdb-eclipse.f692b03a.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position: top left;
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1180px) {
  .heroDiv {
    padding-top: 26%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1140px) {
  .heroDiv {
    padding-top: 28%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
/* media query for tablet */
@media only screen and (max-width: 1024px) {
  .heroDiv {
    padding-top: 30%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 780px) {
  .heroDiv {
    padding-top: 40%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

/* media query for tablets */
@media only screen and (max-width: 768px) {
  .heroDiv {
    padding-top: 45%;
    }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 670px) {
  .heroDiv {
    padding-top: 50%;
    }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 575px) {
  .heroDiv {
    padding-top: 58%;
    }
}

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

  /* media query for mobile phones */
@media only screen and (max-width: 464px) {
  .heroDiv {
    padding-top: 75%;
    }
  
}
.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #FFF9F3;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.contactText {
  font-family: 'Muli', sans-serif;
  font-size: 1.8em;
  line-height: 1.7em;
  font-weight: 700;
  color: black;
  margin-left: 10rem;
  margin-right: 10rem;
  padding-top: 2em;  
}

.contactLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  padding-bottom: 2.8em;
  padding-top: 2.8em;
  }


  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10rem;
  }

  .aboutTextContainer {
    background-color: #FF01D6;
    position: absolute;
    top: 57%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }

  .photoContainer {
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   width: 100%;
  }

.aboutText {
  font-family: 'Muli', sans-serif;
  font-size: 1.8em;
  line-height: 1.7em;
  font-weight: 700;
  color: white;
  margin-left: 10rem;
  margin-right: 10rem;
  padding-top: 1em;
  padding-bottom: 1em;
}

.mailIcon {
  max-width: 57px;
  margin-right: 3em;
}

.githubIcon {
  max-width: 54px;
  margin-right: 3em;
}
.linkedinIcon {
  max-width: 50px;
  margin-right: 3em;
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
@media (max-width: 1900px) {
  .aboutText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  } 
  .contactText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1760px) {
  .aboutText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  } 
  .contactText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1440px) {
  .aboutTextContainer {
    top: 50%;
  }
  .aboutText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  } 
  .contactText {
    font-size: 1.6em;
    line-height: 1.8em;
    font-weight: 600;
  } 
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1345px) {
  .aboutText {
    font-size: 1.4em;
    line-height: 2.1em;
    font-weight: 550;
  } 
  .contactText {
    font-size: 1.4em;
    line-height: 2.1em;
    font-weight: 550;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1140px) {
  .aboutText {
    font-size: 1.4em;
    line-height: 1.8em;
    font-weight: 500;
  } 
  .contactText {
    font-size: 1.4em;
    line-height: 1.8em;
    font-weight: 500;
  } 
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

/* media query for tablet */
@media only screen and (max-width: 1024px) {
  .aboutText { 
    margin-left: 3rem;
    margin-right: 3rem;
    padding-top: 1.1em;
    padding-bottom: 1.1em;
    font-size: 1.3rem;
    line-height: 2rem;
  } 

  .contactText {
    margin-left: 3rem;
    margin-right: 3rem;
    padding-top: 1.1em;
    padding-bottom: 0.5em;
    font-size: 1.4rem; 
  }

  .mailIcon {
    max-width: 52px;
  }
  
  .githubIcon {
    max-width: 50px;
  }
  .linkedinIcon {
    max-width: 46px;
  }

  .contactLinks {
    padding-bottom: 1.3em;
    padding-top: 0.8em;
    }

}

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

/* media query for tablet */
@media only screen and (max-width: 780px) {
  .aboutText { 
    margin-left: 2em;
    margin-right: 2em;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    font-size: 1.2em;
    line-height: 2em;
    font-weight: 550;
  } 

  .contactText { 
    margin-left: 2em;
    margin-right: 2em;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    font-size: 1.2em;
    line-height: 2em;
    font-weight: 550;
  } 
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for mobile phones */
@media only screen and (max-width: 575px) {
  .aboutText { 
    font-size: 1.1em;
    line-height: 2em;
    font-weight: 500;
  } 

  .contactText { 
    font-size: 1.1em;
    line-height: 2em;
    font-weight: 500;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

/* media query for mobile phones */
@media only screen and (max-width: 464px) {
  .aboutText { 
    margin-left: 1.5em;
    margin-right: 1.5em;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    font-size: 1.2em;
    line-height: 2em;
    font-weight: 400;
  } 
  .portrait {
  
  margin-top: -2em;
  width: 100%;
   }
  

   .aboutTextContainer {
    top: 50%; 
  }
  

  .contactContainer {
    top: 50%; 
  }
  
  .contactText {
    margin-left: 1em;
    margin-right: 1.5em;
    padding-top: 1.5em;
    padding-bottom: 1.2em;
    font-size: 1.2em;
    line-height: 2em;
    font-weight: 400;
  }
  
  .contactLinks {
    padding-bottom: 2.8em;
    padding-top: 0.8em;
    }
  
    .mailIcon {
      max-width: 52px;
    }
    
    .githubIcon {
      max-width: 50px;
    }
    .linkedinIcon {
      max-width: 46px;
    }

  

}

.colorBand {
  background-color: #8893ae;
}

.one {
  z-index: 50;
}

.stackContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.stackIcon1 {
  padding-top: 2rem;
  padding-bottom: 1rem;
  width: 12%;  
}

.stackIcon2 {
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 12%;  
}

.stackIconProg {
  width: 12%; 
}

.stackIconHtml {
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 9%;  
}

.stackIconJQ {
  padding-top: 2rem;
  padding-bottom: 1rem;
  width: 10%;  
}

.stackIconJS {
  padding-top: 2rem;
  padding-bottom: 0;
  width: 9%;  
}

.stackIcon3 {
  padding-top: 2rem;
  width: 12%; 
  padding-bottom: 0; 
}

.rowContainer3,
.rowContainer2,
.rowContainer1 {
  display: flex;
  justify-content: space-between;
  z-index: 50;
}

.rowContainer1 {
  align-items: flex-end;
  margin-left: 10em;
  margin-right: 10em;
}

.rowContainer2 {
  align-items: center;
  margin-left: 10em;
  margin-right: 10em;
}

.rowContainer3 {
  align-items: flex-start;
  margin-left: 10em;
  margin-right: 10em;
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
@media (max-width: 1900px) {
  .stackContainer {
    top: 53%;
  }
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 11%;  
  }
  .stackIconProg {
    width: 10%; 
  }
  .stackIconHtml {
    width: 8%;  
  }
  .stackIconJQ {
    width: 10%;  
  }
  .stackIconJS {
    width: 9%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1440px) {
  .stackContainer {
    top: 53%;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1345px) {
  .stackIcon1 {
    width: 17%;  
  }
  .stackIcon2 {
    width: 17%;  
  }  
  .stackIcon3 {
    width: 17%;  
  }
  .stackIconJQ {
    width: 12%;  
  }
  .stackIconJS {
    width: 10%;  
  }
  .stackIconProg {
    width: 14%; 
  }
  .stackIconHtml {
    width: 10%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1140px) {
  .stackIcon1 {
    width: 15%;  
  }
  .stackIcon2 {
    width: 15%;  
  }  
  .stackIconJQ {
    width: 12%;  
  }
  .stackIconJS {
    width: 10%;  
  }
  .stackIcon3 {
    width: 15%;  
  } 
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1115px) {
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 20%;  
  }
  .stackIconProg {
    width: 17%; 
  }
  .stackIconHtml {
    width: 14%;  
  }
  .stackIconJQ {
    width: 16%;  
  }
  .stackIconJS {
    width: 13%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1024px) {
  .rowContainer1, 
  .rowContainer2, 
  .rowContainer3  { 
    margin-left: 3em;
    margin-right: 3em;
  }
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 18%;  
  }
  .stackIconProg {
    width: 16%; 
  }
  .stackIconHtml {
    width: 12%;  
  }
  .stackIconJQ {
    width: 14%;  
  }
  .stackIconJS {
    width: 11%;  
  }
 
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

@media only screen and (max-width: 678px) {
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 22%;  
  }
  .stackIconProg {
    width: 18%; 
  }
  .stackIconHtml {
    width: 14%;  
  }
  .stackIconJQ {
    width: 16%;  
  }
  .stackIconJS {
    width: 14%;  
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for tablet */
@media only screen and (max-width: 600px) {
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 23%;  
  }
  .stackIconProg {
    width: 19%; 
  }
  .stackIconHtml {
    width: 15%;  
  }
  .stackIconJQ {
    width: 18%;  
  }
  .stackIconJS {
    width: 16%;  
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for mobile phones */
@media only screen and (max-width: 575px) {
  
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

/* media query for mobile phones */
@media only screen and (max-width: 464px) {
  .rowContainer1, 
  .rowContainer2, 
  .rowContainer3  { 
    margin-left: 1.2em;
    margin-right: 1.2em;
  }
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 24%;  
  }
  .stackIconProg {
    width: 19%; 
  }
  .stackIconHtml {
    width: 14%;  
  }
  .stackIconJQ {
    width: 16%;  
  }
  .stackIconJS {
    width: 15%;  
  }

}



.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
}

.headerContainerBlck {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 200;
}

.headerDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10em;
  margin-right: 10em;
  margin-top: 2em;
  align-items: center;
  z-index: 100;
}

header {
  z-index: 100; 
}

a {
  text-decoration: none;
}

.homeLink{
  font-family: 'Anton', sans-serif;
  font-size: 1.5em;
  font-weight: regular;
  color: white;
}

.homeLinkBlck{
  font-family: 'Anton', sans-serif;
  font-size: 1.5em;
  font-weight: regular;
  color: black;
}

.position {
  font-family: 'Muli', sans-serif;
  font-size: 1em;
  font-weight: regular;
  color: white;
  margin-left: 2em;
}

.positionBlck {
  font-family: 'Muli', sans-serif;
  font-size: 1em;
  font-weight: regular;
  color: black;
  margin-left: 2em;
}

.leftDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.rightDiv{
 display: flex;
 flex-direction: row;
 justify-content: flex-end;
 align-items: center;
  width: 40%;
  align-content: center;

}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1900px) {
  .homeLink{
    font-size: 1.2em;
  }
  .homeLinkBlck{
    font-size: 1.2em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1440px) {
  .homeLink{
    font-size: 1.3em;
  }
  
  .homeLinkBlck{
    font-size: 1.3em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1024px) {
  .headerDiv {
    margin-left: 3em;
    margin-right: 3em;
  }
  .position {
    font-size: 0.8em;
    font-weight: 400;
  }
  
  .positionBlck {
    font-size: 0.8em;
    font-weight: 400;
  }

  .homeLink{
    font-size: 1.2em;
  }
  
  .homeLinkBlck{
    font-size: 1.2em;
  }

  }
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 768px) {
  .position {
    font-size: 0.9em;
    font-weight: 400;
  }
  
  .positionBlck {
    font-size: 0.9em;
    font-weight: 400;
  }
  .homeLink{
    font-size: 1.2em;
  }
  
  .homeLinkBlck{
    font-size: 1.2em;
  }
}

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 678px) {
  .position {
    font-size: 0.8em;
    font-weight: 400;
  }
  .positionBlck {
    font-size: 0.8em;
    font-weight: 400;
  }
  .homeLink{
    font-size: 1.1em;
  }
  .homeLinkBlck{
    font-size: 1.1em;
  }
}

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 575px) {
  .heroDiv {
    padding-top: 58%;
    }
  .homeLink {
    font-size: 1.1em;
  }
  .homeLinkBlck {
    font-size: 1.1em;
  }
  .position {
    display: none;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
  @media only screen and (max-width: 464px) {
    .headerDiv {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
    .positionBlck {
      font-size: 0.8rem;
      font-weight: 700;
      margin-left: 0.8rem;
      min-width: 80%;
    }
    .homeLink {
      font-size: 1.5em;
    }
    .homeLinkBlck {
      font-size: 1.5em;
    }
    
    .rightDivSub {
      display: flex;
      height:2em;
      align-items: center;
      justify-content: center;
    }

  }




.layout {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items:center;

  width: 100%;
  min-height: 96vh;

  
}


.nav-toggle {
  display: flex;
  height: 1.5em;
  cursor: pointer; 
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1900px) {
  .nav-toggle {
    height: 1.3em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1440px) {
  .nav-toggle {
    height: 1.3em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1024px) {
  .nav-toggle {
    height: 1.0em;
  }
  }
  /* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 575px) {
  .nav-toggle {
    height: 1em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 550px) {
  .nav-toggle {
    height: 0.9em;
  }
  }

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */ 

@media only screen and (max-width: 464px) {
  .nav-toggle {
    height: 1.3em;
  }
}
.projectImage:after {
  margin-top: 11em;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  white-space: pre-wrap;
  background-color: rgba(54, 54, 54, 0.849);
  color: white;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 1.8em;
  font-family: "Muli", sans-serif;
  padding: 1.5em 2.8em;
  opacity: 0;
  content: attr(data-text);
  transition: 0.5s;
}

.project-thumbnail {
  object-fit: cover;
  max-height: 80%;
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1760px) {
  .projectImage:after {
    margin-top: 13em;
    padding-top: 0.5em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1650px) {
  .projectImage:after {
    margin-top: 14em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1345px) {
  .projectImage:after {
    margin-top: 18em;
    font-size: 1.0em;
    line-height: 1.6em;
    padding-top: 0.5em;
    
  }
  .projectImage:first-line {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.6em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1180px) {
  .projectImage:after {
    margin-top: 16em;
    line-height: 1.8em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1140px) {
  .projectImage:after {
    margin-top: 17em;
    font-size: 1.0em;
    line-height: 1.6em;
    padding-top: 0.5em; 
  }
  .projectImage:first-line {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.6em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1115px) {
  .projectImage:after {
    margin-top: 15em;
    line-height: 1.8em;
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for tablet */
@media only screen and (max-width: 1024px) {
  .projectImage:after {
    margin-top: 19em;
    font-size: 0.9em;
    line-height: 1.5em;
    padding: 0.8em 3em;
    display: flex;
    text-align: center;
  }

  .projectImage:hover:after {
    padding-top: 0.5em;
  }

  .projectImage:first-line {
    font-size: 1.5em;
    line-height: 1.5em;
  }

  .project-thumbnail {
    object-fit: cover;
    max-height: 80%;
    max-width: 85%;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 780px) {
  .projectImage:after {
    margin-top: 19em;
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */

/* media query for mobile phones */
@media only screen and (max-width: 768px) {
  .projectImage:after {
    margin-top: 20em;
    font-size: 1em;
    line-height: 1.7em;
  }

  .projectImage:hover:after {
    padding-top: 0.5em;
  }

  .projectImage:first-line {
    font-size: 1em;
    line-height: 1.7em;
  }

}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

@media only screen and (max-width: 678px) {
  .projectImage:after {
    margin-top: 15em;
    font-size: 0.9em;
    line-height: 1.9em;
  }

  .projectImage:hover:after {
    padding-top: 0.5em;
  }

  .projectImage:first-line {
    font-size: 0.9em;
    line-height: 1.9em;
  } 
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */

@media only screen and (min-width: 600px) {
  .projectImage:hover:after {
    opacity: 1;
    top: 0;
  }
  .projectImage:first-line {
    color: #ffffff;
    font-weight: 900;
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .project-thumbnail {
    object-fit: cover;
    max-height: 80%;
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for tablet */
@media only screen and (max-width: 600px) {
  .project-thumbnail {
      margin-top: -3.5em;
  }
  .projectImage:after {
    position: unset;
    margin-top: 11em;
    font-size: 0.9rem;
    opacity: 1;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28%; 
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for mobile phones */
@media only screen and (max-width: 575px) {
  .project-thumbnail {
      margin-top: -3.5em;
  }
  
  .projectImage:after {
    position: unset;
    margin-top: 10.5em;
    font-size: 0.9rem;
    opacity: 1;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28%; 
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */

/* media query for mobile phones */
@media only screen and (max-width: 464px) {
  .project-thumbnail {
      margin-top: -3.5em;
  }
  
  .projectImage:after {
    position: unset;
    margin-top: 11em;
    font-size: 1rem;
    opacity: 1;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28%; 
  }
}

.slider {
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  background-color: transparent;
  height: 50vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.slide {
  min-width: 45%;
  height: 50vh;
  transition: 0.5s;
  overflow: hidden;
  background-color: #363636;
  margin-right: 1.5em;
}

.ghbLnk {
  max-width: 1.6vw;
  margin-top: 3%;
  margin-left: 5%;
}

.fwrdSlash {
  color: black;
}

.controllerDesc {
  font-family: "Muli", sans-serif;
  font-weight: regular;
  color: black;
  font-size: 1em;
  line-height: 1.5em;
  margin-left: 10em;
}

.goLeft {
  border: none;
  background-color: transparent;
  transition: 0.5s;
  margin-right: 1em;
  outline: none;
}

.goRight {
  border: none;
  background-color: transparent;
  transition: 0.5s;
  margin-left: 1em;
  outline: none;
}

.goLeft:hover {
  cursor: pointer;
  transition: 0.5s;
  color: black;
}

.goRight:hover {
  cursor: pointer;
  transition: 0.5s;
  color: black;
}

button:active {
  outline: none;
  border: none;
}

button {
  font-family: "Muli", sans-serif;
  font-size: 0.9em;
  font-weight: regular;
  background-color: transparent;
  border: none;
  color: #b2b2b2;
  outline: none;
}

.sliderController {
  background-color: white;
  height: 50vh;
  width: 25%;
  align-self: flex-start;
  align-items: center;
  align-content: flex-start;
  display: flex;
}

.sliderContainer {
  height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1650px) {
  .slide {
    margin-right: 1.2em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1440px) {
  .ghbLnk {
    max-width: 1.8vw;
  }
  .sliderController {
    width: 26%;
  }
  .slide {
    margin-right: 1.2em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1345px) {
  .sliderController {
    width: 30%;
  }
  .slide {
    margin-right: 1.1em;
  }
  .ghbLnk {
    max-width: 2.2vw;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1240px) {
  .sliderController {
    width: 32%;
  }
  .ghbLnk {
    max-width: 2vw;
  }
  .slide {
    margin-right: 1.05em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1180px) {
  .ghbLnk {
    max-width: 2.5vw;
    margin-top: 5%;
    margin-left: 5%;
  }
  .sliderController {
    width: 34%;
  }
  .slide {
    margin-right: 0.9em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1140px) {
  .sliderController {
    width: 35%;
  }
  .ghbLnk {
    max-width: 2.3vw;
  }
  .slide {
    margin-right: 0.9em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1115px) {
  .sliderController {
    width: 36%;
  }
  .ghbLnk {
    max-width: 2.7vw;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1115px) {
  .sliderController {
    width: 40%;
  }
  .slide {
    margin-right: 0.8em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1024px) {
  .slide {
    min-width: 86.5%;
    margin-right: 1.4em;
  }

  .ghbLnk {
    max-width: 2.8vw;
    margin-top: 3%;
    margin-left: 7.5%;
  }

  .controllerDesc {
    font-size: 0.8rem;
    line-height: 1.8em;
    margin-left: 3em;
  }

  .goLeft {
    margin-right: 0.8rem;
  }

  .goRight {
    margin-left: 0.8rem;
  }

  button {
    font-size: 0.9rem;
  }
  .fwrdSlash {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 0.9rem;
  }
  .sliderController {
    width: 24%;
  }
  .descText {
    font-size: 0.8rem; 
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 780px) {
  .sliderController {
    width: 28%;
  }
  .slide {
    margin-right: 1.1em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 678px) {
  .sliderController {
    width: 34%;
  }
  .slide {
    margin-right: 0.9em;
  }
  .ghbLnk {
    max-width: 4.5vw;
    margin-top: 5.5%;
    margin-left: 7.5%;
  }
}

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

@media only screen and (max-width: 642px) {
  
  .ghbLnk {
    max-width: 4.4vw;
  }
  .controllerDesc {
    font-size: 0.8rem;
    line-height: 1.7em;
  }

  .fwrdSlash {
    font-size: 0.8rem;
  }
  .sliderController {
    width: 39%;
  }
  .descText {
    font-size: 0.8rem; 
  }

}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 600px) {
  .sliderController {
    width: 40%;
  }
  .slide {
    margin-right: 0.8em;
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for mobile phones */
@media only screen and (max-width: 575px) {
  .ghbLnk {
    max-width: 5.5vw;
  }
  .goLeft {
    margin-right: 0.3em;
  }
  .goRight {
    margin-left: 0em;
  }

  button {
    font-size: 0.8rem;
  }

  .sliderController {
    width: 35%;
  }

  .descText {
    display: none;
  }
  .fwrdSlash {
    margin-left: 0;
    margin-right: 0.5em;
    padding-left: 0;
    padding-right: 0;
  } 
  .slide {
    margin-right: 0.7em;
  }
}


/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

@media only screen and (max-width: 464px) {
  .sliderContainer {
    max-height: 100%;
    overflow: hidden;
    display: flex;
  }
  
  .slide {
    margin-right: 3%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: hidden;
    display: flex;
    position: unset;
    height: 100%;
  }


  .ghbLnk {
    max-width: 7vw;
    margin-left: 1em;
    justify-content: center;
    align-items: center;
    margin-top: 1.5em;
  }

  .controllerDesc {
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: 1.2rem;
    margin-right: 0.5rem;
  }

  .goLeft {
    margin-right: 0.3em;
  }

  .goRight {
    margin-left: 0em;
  }

  button {
    font-size: 1rem;
  }

  .sliderController {
    width: 30%;
  }

  .descText {
    display: none;
  }
  .fwrdSlash {
    margin-left: 0;
    margin-right: 0.5em;
    padding-left: 0;
    padding-right: 0;
  }
}

.footerDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10em;
  margin-bottom: 1em;
  align-content: center;
  font-family: 'Muli', sans-serif;
  font-size: 0.9em;
  font-weight: regular;
  color: black;
  margin-top: -1.5em;
}

/* ================================================== */ 
/* ================================================== */

@media only screen and (max-width: 1024px) {
  .footerDiv {
    margin-left: 3em;
    margin-bottom: 1em;
    font-size: 0.8em;
  }
  }

/* ================================================== */ 
/* ================================================== */

/* media query for mobile phones */
@media only screen and (max-width: 464px) {
  .footerDiv {
    margin-left: 1.4em;
    font-size: 0.8em;
  }

}
