.projectImage:after {
  margin-top: 11em;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  white-space: pre-wrap;
  background-color: rgba(54, 54, 54, 0.849);
  color: white;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 1.8em;
  font-family: "Muli", sans-serif;
  padding: 1.5em 2.8em;
  opacity: 0;
  content: attr(data-text);
  transition: 0.5s;
}

.project-thumbnail {
  object-fit: cover;
  max-height: 80%;
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1760px) {
  .projectImage:after {
    margin-top: 13em;
    padding-top: 0.5em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1650px) {
  .projectImage:after {
    margin-top: 14em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1345px) {
  .projectImage:after {
    margin-top: 18em;
    font-size: 1.0em;
    line-height: 1.6em;
    padding-top: 0.5em;
    
  }
  .projectImage:first-line {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.6em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1180px) {
  .projectImage:after {
    margin-top: 16em;
    line-height: 1.8em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1140px) {
  .projectImage:after {
    margin-top: 17em;
    font-size: 1.0em;
    line-height: 1.6em;
    padding-top: 0.5em; 
  }
  .projectImage:first-line {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.6em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1115px) {
  .projectImage:after {
    margin-top: 15em;
    line-height: 1.8em;
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for tablet */
@media only screen and (max-width: 1024px) {
  .projectImage:after {
    margin-top: 19em;
    font-size: 0.9em;
    line-height: 1.5em;
    padding: 0.8em 3em;
    display: flex;
    text-align: center;
  }

  .projectImage:hover:after {
    padding-top: 0.5em;
  }

  .projectImage:first-line {
    font-size: 1.5em;
    line-height: 1.5em;
  }

  .project-thumbnail {
    object-fit: cover;
    max-height: 80%;
    max-width: 85%;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 780px) {
  .projectImage:after {
    margin-top: 19em;
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */

/* media query for mobile phones */
@media only screen and (max-width: 768px) {
  .projectImage:after {
    margin-top: 20em;
    font-size: 1em;
    line-height: 1.7em;
  }

  .projectImage:hover:after {
    padding-top: 0.5em;
  }

  .projectImage:first-line {
    font-size: 1em;
    line-height: 1.7em;
  }

}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

@media only screen and (max-width: 678px) {
  .projectImage:after {
    margin-top: 15em;
    font-size: 0.9em;
    line-height: 1.9em;
  }

  .projectImage:hover:after {
    padding-top: 0.5em;
  }

  .projectImage:first-line {
    font-size: 0.9em;
    line-height: 1.9em;
  } 
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */

@media only screen and (min-width: 600px) {
  .projectImage:hover:after {
    opacity: 1;
    top: 0;
  }
  .projectImage:first-line {
    color: #ffffff;
    font-weight: 900;
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .project-thumbnail {
    object-fit: cover;
    max-height: 80%;
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for tablet */
@media only screen and (max-width: 600px) {
  .project-thumbnail {
      margin-top: -3.5em;
  }
  .projectImage:after {
    position: unset;
    margin-top: 11em;
    font-size: 0.9rem;
    opacity: 1;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28%; 
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for mobile phones */
@media only screen and (max-width: 575px) {
  .project-thumbnail {
      margin-top: -3.5em;
  }
  
  .projectImage:after {
    position: unset;
    margin-top: 10.5em;
    font-size: 0.9rem;
    opacity: 1;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28%; 
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */

/* media query for mobile phones */
@media only screen and (max-width: 464px) {
  .project-thumbnail {
      margin-top: -3.5em;
  }
  
  .projectImage:after {
    position: unset;
    margin-top: 11em;
    font-size: 1rem;
    opacity: 1;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28%; 
  }
}
