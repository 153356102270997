.footerDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10em;
  margin-bottom: 1em;
  align-content: center;
  font-family: 'Muli', sans-serif;
  font-size: 0.9em;
  font-weight: regular;
  color: black;
  margin-top: -1.5em;
}

/* ================================================== */ 
/* ================================================== */

@media only screen and (max-width: 1024px) {
  .footerDiv {
    margin-left: 3em;
    margin-bottom: 1em;
    font-size: 0.8em;
  }
  }

/* ================================================== */ 
/* ================================================== */

/* media query for mobile phones */
@media only screen and (max-width: 464px) {
  .footerDiv {
    margin-left: 1.4em;
    font-size: 0.8em;
  }

}