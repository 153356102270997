@import url("https://fonts.googleapis.com/css2?family=Anton&family=Lato&family=Muli:wght@400;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 16px;
}

body {
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Anton", sans-serif;
  font-family: "Muli", sans-serif;
  height: 98vh;
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 18px;
  }
}

/* media query for mobile phones */
/* show only one slide */
@media only screen and (max-width: 464px) {
  html {
    font-size: 14px;
  }
}
