.colorBand {
  background-color: #8893ae;
}

.one {
  z-index: 50;
}

.stackContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.stackIcon1 {
  padding-top: 2rem;
  padding-bottom: 1rem;
  width: 12%;  
}

.stackIcon2 {
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 12%;  
}

.stackIconProg {
  width: 12%; 
}

.stackIconHtml {
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 9%;  
}

.stackIconJQ {
  padding-top: 2rem;
  padding-bottom: 1rem;
  width: 10%;  
}

.stackIconJS {
  padding-top: 2rem;
  padding-bottom: 0;
  width: 9%;  
}

.stackIcon3 {
  padding-top: 2rem;
  width: 12%; 
  padding-bottom: 0; 
}

.rowContainer3,
.rowContainer2,
.rowContainer1 {
  display: flex;
  justify-content: space-between;
  z-index: 50;
}

.rowContainer1 {
  align-items: flex-end;
  margin-left: 10em;
  margin-right: 10em;
}

.rowContainer2 {
  align-items: center;
  margin-left: 10em;
  margin-right: 10em;
}

.rowContainer3 {
  align-items: flex-start;
  margin-left: 10em;
  margin-right: 10em;
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
@media (max-width: 1900px) {
  .stackContainer {
    top: 53%;
  }
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 11%;  
  }
  .stackIconProg {
    width: 10%; 
  }
  .stackIconHtml {
    width: 8%;  
  }
  .stackIconJQ {
    width: 10%;  
  }
  .stackIconJS {
    width: 9%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1440px) {
  .stackContainer {
    top: 53%;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1345px) {
  .stackIcon1 {
    width: 17%;  
  }
  .stackIcon2 {
    width: 17%;  
  }  
  .stackIcon3 {
    width: 17%;  
  }
  .stackIconJQ {
    width: 12%;  
  }
  .stackIconJS {
    width: 10%;  
  }
  .stackIconProg {
    width: 14%; 
  }
  .stackIconHtml {
    width: 10%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1140px) {
  .stackIcon1 {
    width: 15%;  
  }
  .stackIcon2 {
    width: 15%;  
  }  
  .stackIconJQ {
    width: 12%;  
  }
  .stackIconJS {
    width: 10%;  
  }
  .stackIcon3 {
    width: 15%;  
  } 
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1115px) {
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 20%;  
  }
  .stackIconProg {
    width: 17%; 
  }
  .stackIconHtml {
    width: 14%;  
  }
  .stackIconJQ {
    width: 16%;  
  }
  .stackIconJS {
    width: 13%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1024px) {
  .rowContainer1, 
  .rowContainer2, 
  .rowContainer3  { 
    margin-left: 3em;
    margin-right: 3em;
  }
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 18%;  
  }
  .stackIconProg {
    width: 16%; 
  }
  .stackIconHtml {
    width: 12%;  
  }
  .stackIconJQ {
    width: 14%;  
  }
  .stackIconJS {
    width: 11%;  
  }
 
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

@media only screen and (max-width: 678px) {
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 22%;  
  }
  .stackIconProg {
    width: 18%; 
  }
  .stackIconHtml {
    width: 14%;  
  }
  .stackIconJQ {
    width: 16%;  
  }
  .stackIconJS {
    width: 14%;  
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for tablet */
@media only screen and (max-width: 600px) {
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 23%;  
  }
  .stackIconProg {
    width: 19%; 
  }
  .stackIconHtml {
    width: 15%;  
  }
  .stackIconJQ {
    width: 18%;  
  }
  .stackIconJS {
    width: 16%;  
  }
}
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* media query for mobile phones */
@media only screen and (max-width: 575px) {
  
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

/* media query for mobile phones */
@media only screen and (max-width: 464px) {
  .rowContainer1, 
  .rowContainer2, 
  .rowContainer3  { 
    margin-left: 1.2em;
    margin-right: 1.2em;
  }
  .stackIcon3, 
  .stackIcon2, 
  .stackIcon1 {
    width: 24%;  
  }
  .stackIconProg {
    width: 19%; 
  }
  .stackIconHtml {
    width: 14%;  
  }
  .stackIconJQ {
    width: 16%;  
  }
  .stackIconJS {
    width: 15%;  
  }

}
