.nav-toggle {
  display: flex;
  height: 1.5em;
  cursor: pointer; 
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1900px) {
  .nav-toggle {
    height: 1.3em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1440px) {
  .nav-toggle {
    height: 1.3em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1024px) {
  .nav-toggle {
    height: 1.0em;
  }
  }
  /* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 575px) {
  .nav-toggle {
    height: 1em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 550px) {
  .nav-toggle {
    height: 0.9em;
  }
  }

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */ 

@media only screen and (max-width: 464px) {
  .nav-toggle {
    height: 1.3em;
  }
}