.layout {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items:center;

  width: 100%;
  min-height: 96vh;

  
}

