

.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
}

.headerContainerBlck {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 200;
}

.headerDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10em;
  margin-right: 10em;
  margin-top: 2em;
  align-items: center;
  z-index: 100;
}

header {
  z-index: 100; 
}

a {
  text-decoration: none;
}

.homeLink{
  font-family: 'Anton', sans-serif;
  font-size: 1.5em;
  font-weight: regular;
  color: white;
}

.homeLinkBlck{
  font-family: 'Anton', sans-serif;
  font-size: 1.5em;
  font-weight: regular;
  color: black;
}

.position {
  font-family: 'Muli', sans-serif;
  font-size: 1em;
  font-weight: regular;
  color: white;
  margin-left: 2em;
}

.positionBlck {
  font-family: 'Muli', sans-serif;
  font-size: 1em;
  font-weight: regular;
  color: black;
  margin-left: 2em;
}

.leftDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.rightDiv{
 display: flex;
 flex-direction: row;
 justify-content: flex-end;
 align-items: center;
  width: 40%;
  align-content: center;

}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1900px) {
  .homeLink{
    font-size: 1.2em;
  }
  .homeLinkBlck{
    font-size: 1.2em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1440px) {
  .homeLink{
    font-size: 1.3em;
  }
  
  .homeLinkBlck{
    font-size: 1.3em;
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1024px) {
  .headerDiv {
    margin-left: 3em;
    margin-right: 3em;
  }
  .position {
    font-size: 0.8em;
    font-weight: 400;
  }
  
  .positionBlck {
    font-size: 0.8em;
    font-weight: 400;
  }

  .homeLink{
    font-size: 1.2em;
  }
  
  .homeLinkBlck{
    font-size: 1.2em;
  }

  }
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 768px) {
  .position {
    font-size: 0.9em;
    font-weight: 400;
  }
  
  .positionBlck {
    font-size: 0.9em;
    font-weight: 400;
  }
  .homeLink{
    font-size: 1.2em;
  }
  
  .homeLinkBlck{
    font-size: 1.2em;
  }
}

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 678px) {
  .position {
    font-size: 0.8em;
    font-weight: 400;
  }
  .positionBlck {
    font-size: 0.8em;
    font-weight: 400;
  }
  .homeLink{
    font-size: 1.1em;
  }
  .homeLinkBlck{
    font-size: 1.1em;
  }
}

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 575px) {
  .heroDiv {
    padding-top: 58%;
    }
  .homeLink {
    font-size: 1.1em;
  }
  .homeLinkBlck {
    font-size: 1.1em;
  }
  .position {
    display: none;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
  @media only screen and (max-width: 464px) {
    .headerDiv {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
    .positionBlck {
      font-size: 0.8rem;
      font-weight: 700;
      margin-left: 0.8rem;
      min-width: 80%;
    }
    .homeLink {
      font-size: 1.5em;
    }
    .homeLinkBlck {
      font-size: 1.5em;
    }
    
    .rightDivSub {
      display: flex;
      height:2em;
      align-items: center;
      justify-content: center;
    }

  }



