.heroDiv {
  width: 100%;
  padding-top: 21.5%;
  background-image: url(../images/pdb-eclipse.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position: top left;
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1180px) {
  .heroDiv {
    padding-top: 26%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 1140px) {
  .heroDiv {
    padding-top: 28%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
/* media query for tablet */
@media only screen and (max-width: 1024px) {
  .heroDiv {
    padding-top: 30%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 780px) {
  .heroDiv {
    padding-top: 40%;  
  }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

/* media query for tablets */
@media only screen and (max-width: 768px) {
  .heroDiv {
    padding-top: 45%;
    }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 670px) {
  .heroDiv {
    padding-top: 50%;
    }
}
/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */
@media only screen and (max-width: 575px) {
  .heroDiv {
    padding-top: 58%;
    }
}

/* ================================================== */ 
/* ================================================== */
/* ================================================== */ 
/* ================================================== */

  /* media query for mobile phones */
@media only screen and (max-width: 464px) {
  .heroDiv {
    padding-top: 75%;
    }
  
}